import gsap from 'gsap';
import $ from 'jquery';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
window.$ = $;

var tl = gsap.timeline();
gsap.fromTo("body",{
    filter: "blur(40px) brightness(2.5) contrast(20.5) opacity(0)",
},{
    filter: "blur(0px) brightness(1) contrast(1) opacity(1)",
    clearProps: "filter",
    duration: 2,
});


// gsap.fromTo("#mobile",{
//     filter: "blur(40px) brightness(5.5) contrast(10.5) opacity(0)",
// },{
//     filter: "blur(0px) brightness(1) contrast(1) opacity(1)",
//     clearProps: "filter",
//     duration: 4,
// });

var tl = gsap.timeline();
tl.to("#big-image-desktop",{
    scrollTrigger: {
        markers: false,
        start: "top 50%",
        end: "bottom 50%",
        trigger: '#infos',
        scrub:true,
      },
    opacity: 0,
    overwrite: true,
    duration: 1
})

$('.info-spectacle').on('mouseenter', function(e) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    const url = $(this).attr('data-image');
    const image = $('<img>').attr('src', url);
    $('#big-image-desktop').append(image);

    // gsap.fromTo(image, {opacity:0},{opacity:1, duration: 0.5});

    
    gsap.fromTo(image, 
        {filter: "blur(80px) brightness(1) contrast(15.5) opacity(0)"},
        {filter: "blur(0px) brightness(1) contrast(1) opacity(1)", duration: 1});

    // get other image in the same div and hide it with gsap``

    const otherImage = $(image).siblings('img'); 
    console.log(otherImage)
    gsap.to(otherImage, {opacity:0, duration: 1.5, delay: 0.0});


});


$('.info-spectacle').on('mouseleave', function(e) {
    e.stopPropagation();

    const url = $('.logo').attr('data-image');
    const image = $('<img>').attr('src', url);
    $('#big-image-desktop').append(image);
    // gsap.fromTo(image, {opacity:0},{opacity:1, duration: 0.5});

    gsap.fromTo(image, 
        {filter: "blur(80px) brightness(1) contrast(15.5) opacity(0)"},
        {filter: "blur(0px) brightness(1) contrast(1) opacity(1)", duration: 1});

    // get other image in the same div and hide it with gsap``

    const otherImage = $(image).siblings('img'); 
    console.log(otherImage)
    gsap.to(otherImage, {opacity:0, duration: 1.5, delay: 0.0});
});

// --------------------------------------------------

// with gsap scrollDown to the div with id infos when clicking on the button "plus-btn"

$('#plus-btn').on('click', function() {
    gsap.to(window, {duration: 0.5, scrollTo: "#infos"});
}
);

$('#plus-btn-mobile').on('click', function() {
    gsap.to(window, {duration: 0.5, scrollTo: "#infos-mobile"});
}
);
